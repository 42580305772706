.clicker {
  h1 {
    text-align: left;
  }

  .inning {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }

  .clear-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(50% - 10px);
    height: 44px;
    border-radius: 5px;
  }

  .game {
    display: flex;
    justify-content: space-between;
    .counter {
      margin-bottom: 0;
    }
  }

  .inning-count {
    position: relative;
  }

  .inning-description {
    position: absolute;
    display: inline-block;
    font-size: 1.5rem;
    &.top {
      top: 17px;
      right: 6px;
      transform: rotate(-90deg);
    }
    &.bottom {
      right: 0px;
      top: 19px;
      transform: rotate(90deg);
    }
  }
}
