.page-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 50px;
  width: 100%;
  padding: 0 20px;
  opacity: 0.9;
  background-color: #fff;
  a {
    color: #131313;
  }
  span {
    display: inline-block;
    padding: 0 20px;
  }
}
