@import-normalize;

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
}

#root {
  position: relative;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  background-color: #dedede;
  background-image: url(/images/background.jpg);
  background-size: cover;
  background-position: center center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.content-container {
  background-color: #fff;
  opacity: 0.9;
  padding: 1.875rem;
  text-align: center;
  width: calc(100% - 2.5rem);
  min-width: 280px;
  max-width: 450px;
  border-radius: 5px;
  margin-bottom: 1.25rem;
}

.primary-button {
  display: inline-block;
  width: 100%;
  padding: 1rem 1.25rem;
  border-radius: 0.25rem;
  background-color: #28a745;
  border-color: #28a745;
  color: #fff;
  text-decoration: none;
  margin-bottom: 1rem;
  &:hover {
    cursor: pointer;
    background-color: #218838;
    border-color: #1e7e34;
  }
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    background-color: #28a745;
  }
}

h1 {
  font-size: 1.4rem;
  margin: 0 0 0.625rem;
}

h2 {
  font-size: 1.2rem;
  margin: 0 0 0.625rem;
}

h4 {
  font-size: 1.15rem;
  margin: 0 0 0.3125rem;
}

.waiter {
  position: relative;
  width: 50px;
  height: 50px;
  margin: 25px auto;
  &:before {
    content: "";
    display: block;
    background-color: #dedede;
    animation: lightgrey 1.5s infinite;
    width: 50px;
    height: 50px;
    position: absolute;
    border-radius: 50%;
    opacity: 0.5;
  }
  &:after {
    content: "";
    display: block;
    background-color: #888;
    animation: darkgrey 1.5s infinite;
    width: 50px;
    height: 50px;
    position: absolute;
    border-radius: 50%;
    opacity: 0.5;
  }
}

@keyframes lightgrey {
  0% {
    transform: scaleX(0) scaleY(0);
  }
  50% {
    transform: scaleX(1) scaleY(1);
  }
  100% {
    transform: scaleX(0) scaleY(0);
  }
}

@keyframes darkgrey {
  0% {
    transform: scaleX(1) scaleY(1);
  }
  50% {
    transform: scaleX(0) scaleY(0);
  }
  100% {
    transform: scaleX(1) scaleY(1);
  }
}
