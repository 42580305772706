.counter {
  position: relative;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  width: calc(33% - 25px);
  min-width: 60px;
  &.top:before,
  &.bottom:after {
    content: "\203A";
    display: inline-block;
    position: absolute;
    font-size: 2rem;
    color: #ccc;
  }
  &.top:before {
    top: 76px;
    right: 12px;
    transform: rotateZ(-90deg);
  }
  &.bottom:after {
    top: 78px;
    right: 5px;
    transform: rotateZ(90deg);
  }
  &:nth-of-type(2) {
    h4 {
      text-align: center;
    }
    .count-container {
      justify-content: center;
      align-items: center;
    }
  }
  &:nth-of-type(3) {
    h4 {
      text-align: right;
    }
    .count-container {
      justify-content: flex-end;
      align-items: flex-end;
    }
  }
}

.count-container {
  display: flex;
  flex-direction: column;
  align-items: left;
  border-radius: 5px;
  width: 100%;
  &:nth-of-type(2) {
    justify-content: center;
    align-items: center;
    outline: 1px solid red;
  }
  &:nth-of-type(3) {
    justify-content: flex-end;
    align-items: flex-end;
  }
}

.count-number {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 100%;
  padding: 0 0.75rem;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

button {
  height: 44px;
  width: 100%;
  border: 1px solid #ddd;
  -webkit-appearance: none;
}

.minus-button {
  border-radius: 0 0 5px 5px;
  background-color: #eee;
}

.plus-button {
  border-radius: 5px 5px 0 0;
  background-color: #ccc;
}
