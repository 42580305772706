.team-picker {
  h1 {
    font-size: 1.4rem;
  }
  h2 {
    font-size: 1.2rem;
    text-align: left;
    margin-bottom: 0.3125rem;
  }
}
