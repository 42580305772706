.page-header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 100%;
  padding: 0 20px;
  margin-bottom: 1.25rem;
  opacity: 0.9;
  background-color: #fff;
  font-size: 1.5rem;
  font-weight: 700;
  font-family: "Paytone One", sans-serif;
  letter-spacing: 0.5px;
  a {
    color: #131313;
    text-decoration: none;
  }
}
