.current-games {
  ul {
    padding: 0;
    margin: 0 auto;
    li {
      list-style-type: none;
      padding: 0.5rem;
    }
  }
}
