.team-drop-down {
  position: relative;
  margin-bottom: 1rem;
  &.error {
    outline: 1px solid #ff9494;
  }
  &:before {
    content: "";
    display: inline-block;
    width: 0.625rem;
    height: 0.625rem;

    position: absolute;
    right: 1rem;
    top: 50%;

    border-style: solid;
    border-color: #888;
    border-width: 0px 1px 1px 0px;
    transform: rotate(45deg) translateY(-50%);

    pointer-events: none;
  }
  select {
    -webkit-appearance: none;
    border: 1px solid #dedede;
    width: 100%;
    height: 2.75rem;
    padding: 0.5rem;
  }
}
